import Vue from 'vue';
import router from './router';
import App from './App.vue';
import store from './store/store';

import { Instrument } from './types';

//Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
// internal icons
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(fas);
Vue.component('vue-fontawesome', FontAwesomeIcon);

// Google Analytics
import VueGtag from 'vue-gtag';
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTAG },
});

// Buefy/frontend stuff
import Buefy from 'buefy';
import './sass/styles.scss';

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
  customIconPacks: {
    fas: {
      sizes: {
        default: 'lg',
        'is-small': '1x',
        'is-medium': '2x',
        'is-large': '3x',
      },
      iconPrefix: '',
    },
  },
});

Vue.config.productionTip = false;

Vue.filter('titleize', titleize);
Vue.filter('numberToEnsembleSize', numberToEnsembleSize);
Vue.filter('numberToRoman', numberToRoman);
Vue.filter('displayInstrumentName', displayInstrumentName);
Vue.filter('keySymbols', keySymbols);
Vue.filter('intToCurrency', intToCurrency);
Vue.filter('capitalizeFirstLetter', capitalizeFirstLetter);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');

// ============================================================================
// ============================================================================
// ============================================================================

function capitalizeFirstLetter(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

function titleize(value: string): string {
  return value.replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
}

function numberToEnsembleSize(val: number): string {
  switch (val) {
    case 1:
      return 'solo';
    case 2:
      return 'duo';
    case 3:
      return 'trio';
    case 4:
      return 'quartet';
    case 5:
      return 'quintet';
    case 6:
      return 'sextet';
    case 7:
      return 'septet';
    case 8:
      return 'octet';
    case 9:
      return 'nonet';
    case 10:
      return 'dectet';
  }
  return '';
}

function intToCurrency(value: any): string {
  if (typeof value !== 'number') {
    return value;
  }
  value = value / 100; //Add decimal points for cents.
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
}

function numberToRoman(num: any): any {
  if (isNaN(num)) return NaN;
  let digits = String(+num).split(''),
    key = [
      '',
      'C',
      'CC',
      'CCC',
      'CD',
      'D',
      'DC',
      'DCC',
      'DCCC',
      'CM',
      '',
      'X',
      'XX',
      'XXX',
      'XL',
      'L',
      'LX',
      'LXX',
      'LXXX',
      'XC',
      '',
      'I',
      'II',
      'III',
      'IV',
      'V',
      'VI',
      'VII',
      'VIII',
      'IX',
    ],
    roman = '',
    i = 3;
  while (i--) {
    const digit = digits.pop();
    const j = digit ? +digit + i * 10 : false;
    if (j && Object.prototype.hasOwnProperty.call(key, j)) {
      roman = (key[j] || '') + roman;
    }
  }

  return Array(+digits.join('') + 1).join('M') + roman;
}

function displayInstrumentName(
  instrument: Instrument,
  accidentalSpan: boolean = true,
  includeKey: boolean = true
): string {
  if (!instrument) return 'Not available';

  let instrumentName = instrument.label;
  if (includeKey && instrument.transposition !== 'C') {
    if (accidentalSpan) {
      const found = instrument.transposition.match(
        /(?<letter>[A-G])(?<accidental>#|b|x|bb|)/
      );

      if (found?.groups) {
        found.groups.accidental = accidental(found.groups.accidental);
        instrumentName += ` in ${found.groups.letter}${found.groups.accidental}`;
      }
    } else {
      instrumentName += ` in ${instrument.transposition}`;
    }
  }
  return instrumentName;
}

function keySymbols(val: string): string {
  const found = val.match(
    /(?<letter>[A-G])(?<accidental>#|b|x|bb|) (?<mode>major|minor)/
  );

  if (found?.groups) {
    return (
      `${found.groups.letter}` +
      accidental(found.groups.accidental) +
      ` ${found.groups.mode}`
    );
  }

  return '';
}

function accidental(str: string): string {
  switch (str) {
    //TODO: should we find a way to keep the b and # characters for screen readers and copy & paste?
    case 'b':
      str = '<span class="bravura-text flat"></span>';
      break;
    case '#':
      str = '<span class="bravura-text sharp"></span>';
      break;
    case 'x':
      str = '<span class="bravura-text doublesharp"></span>';
      break;
    case 'bb':
      str = '<span class="bravura-text doubleflat"></span>';
      break;
  }

  return str;
}
