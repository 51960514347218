<template>
  <div id="app">
    <div :class="['sticky', { 'has-shadow': addStickyShadow }]">
      <Header />
      <nav-bar />
    </div>

    <breadcrumb :list="breadcrumbList" />
    <!--
      <template v-slot>
        <Suspense compact />
      </template>
      -->
    <Suspense v-if="isLoading" />
    <router-view v-show="!isLoading" />
    <footer-bar />
  </div>
</template>

<script>
import Header from '@/components/Header';
import NavBar from '@/components/NavBar';
import FooterBar from '@/components/FooterBar';
import Breadcrumb from '@/components/Breadcrumb';
import Suspense from '@/components/Suspense';
import $eventHub from '@/components/EventHub';

export default {
  name: 'app',
  components: {
    Header,
    NavBar,
    Breadcrumb,
    FooterBar,
    Suspense,
  },
  data() {
    return {
      breadcrumbList: [],
      isLoading: true,
      scrollPos: 0,
      shadowScrollThreshold: 30,
    };
  },
  computed: {
    addStickyShadow: function () {
      return this.scrollPos > this.shadowScrollThreshold;
    },
  },
  methods: {
    updateBreadcrumbs() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    start() {
      this.isLoading = true;
    },
    stop() {
      this.isLoading = false;
    },
    updateScrollPos: function () {
      this.scrollPos = window.scrollY;
    },
  },
  mounted() {
    this.updateBreadcrumbs();
    $eventHub.$on('componentLoading', this.start);
    $eventHub.$on('componentLoaded', this.stop);
    document.addEventListener('scroll', this.updateScrollPos);
  },
  watch: {
    $route() {
      this.updateBreadcrumbs();
    },
  },
  created() {
    this.$store.dispatch('loadAllData'); // dispatch loading of state from database.

    // nothing defined here (when this.$route.path is other than "/")
    // console.log(this.$route, this.$route.meta.layout);
  },
  updated() {
    // something defined here whatever the this.$route.path
    // console.log(this.$route, this.$route.meta.layout);
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/styles';
body {
  background: $background;
}
.sticky {
  position: sticky;
  top: 0;
  z-index: 20;
  transition: box-shadow 200ms ease-in-out;
}
.has-shadow {
  box-shadow: rgba(48, 48, 48, 0.1) 0px 10px 75px;
}

.container {
  background: #ffffff;
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
}
</style>
