export function initState() {
  return {
    loading: true,
    pieces: [],
    instruments: [],
    ensembles: [],
    parts: [],
    userEnsemble: [],
    ensembleReady: {
      2: false,
      3: false,
      4: false,
      5: false,
    },
    userEnsembleSorted: {
      2: {
        1: [],
        2: [],
      },
      3: {
        1: [],
        2: [],
        3: [],
      },
      4: {
        1: [],
        2: [],
        3: [],
        4: [],
      },
      5: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
      },
    },
    userEnsembleId: null,
    userCart: [],
    stepIndex: 0,
    ensembleSidebarOpen: false,
    checkoutSidebarOpen: false,
  };
}

export function getInitState(key) {
  let init = initState();
  if (key in init) {
    return initState()[key];
  }
  return null;
}
