import Vuex from 'vuex';
import Vue from 'vue';
import musicData from '../../db.json';

import { session } from './sessionStorage.js';
import { initState, getInitState } from './initState.js';
import {
  calculateEnsembleSorted,
  checkEnsembleReady,
} from './ensembleHelpers.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: initState(),
  plugins: [session.plugin],
  actions: {
    fetchData({ commit }, variable) {
      let commitAction =
        'update' + variable.replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
      commit(commitAction, musicData[variable]);
    },
    loadAllData({ commit }) {
      ['pieces', 'instruments', 'ensembles', 'parts'].forEach((val) =>
        this.dispatch('fetchData', val)
      );
      commit('changeLoadingState', false);
    },
  },
  mutations: {
    updatePieces(state, pieces) {
      state.pieces = pieces;
    },
    updateInstruments(state, instruments) {
      state.instruments = instruments;
    },
    updateEnsembles(state, ensembles) {
      state.ensembles = ensembles;
    },
    updateParts(state, parts) {
      state.parts = parts;
    },
    changeLoadingState(state, loading) {
      state.loading = loading;
    },
    changeStepIndex(state, index) {
      state.stepIndex = index;
    },
    updateUserEnsemble(state, ensemble) {
      //First, update the ensemble.
      state.userEnsemble = ensemble;
      //Second, calculate the sorted user ensembles
      state.userEnsembleSorted = calculateEnsembleSorted(state);
      //Finally, calculate the ensemble ready state
      state.ensembleReady = checkEnsembleReady(state);
    },
    updateUserEnsembleId(state, ensembleId) {
      state.userEnsembleId = ensembleId;
    },
    addToCart(state, piece) {
      state.userCart.push(piece);
    },
    updateCart(state, { piece, cartIndex }) {
      state.userCart.splice(cartIndex, 1, piece);
    },
    removeFromCart(state, cartId) {
      //Removes based on the index of the cart.
      state.userCart.splice(cartId, 1);
    },
    removePieceFromCart(state, pieceId) {
      //find the one that matches then remove it.
      state.userCart = state.userCart.filter(function (val) {
        return val.piece.id != pieceId;
      });
    },
    clearCart(state) {
      state.userCart = [];
    },
    //Resets to initial state
    resetUserEnsemble(state) {
      state.userEnsemble = getInitState('userEnsemble');
    },
    resetUserEnsembleSorted(state) {
      state.userEnsembleSorted = getInitState('userEnsembleSorted');
    },
    resetEnsembleReady(state) {
      state.ensembleReady = getInitState('ensembleReady');
    },
    resetAll(state) {
      const s = initState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  getters: {
    pieces: (state) => state.pieces,
    instruments: (state) => state.instruments,
    instrument: (state) => (id) => {
      return state.instruments.find((inst) => inst.id === id);
    },
    ensembles: (state) => state.ensembles,
    parts: (state) => state.parts,
    userEnsemble: (state) => state.userEnsemble,
    userEnsembleSorted: (state) => state.userEnsembleSorted,
    ensembleReady: (state) => state.ensembleReady,
    userEnsembleId: (state) => state.userEnsembleId,
    userCart: (state) => state.userCart,
    getStepIndex: (state) => state.storeIndex,
  },
});
