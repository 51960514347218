<template>
  <div id="checkout_list">
    <b-table
      :data="tableData"
      ref="table"
      hoverable
      narrowed
      detailed
      show-detail-icon
      detail-key="index"
      :card-layout="compact"
    >
      <!-- TODO: there's a bug here that when the table is empty the headers don't show.-->
      <!-- TODO: Need to register each row by the index in the cart, since it should be possible to buy the same piece more than once. -->
      <template v-slot:empty> Nothing in your cart. </template>
      <b-table-column label="Title" width="300" v-slot="props">
        <a @click="toggle(props.row)" v-html="props.row.title"></a>
      </b-table-column>
      <b-table-column label="Base Price" numeric>
        <template v-slot:header="{ column }">
          <b-tooltip
            position="is-bottom"
            label="This price includes the score and a selection of one of each Part indicated by Roman numerals for both the prelude and fugue."
            dashed
            multilined
            append-to-body
          >
            {{ column.label }}
          </b-tooltip>
        </template>
        <template v-slot="props">
          <span class="original-price" v-if="props.row.base_price != '$0.00'">{{
            props.row.original_price
          }}</span>
          {{ props.row.base_price }}
        </template>
      </b-table-column>
      <b-table-column label="Additional Price" numeric>
        <template v-slot:header="{ column }">
          <b-tooltip
            position="is-bottom"
            label="This price includes any selected parts not covered by the base price. Parts are counted individually for the Prelude and Fugue."
            dashed
            multilined
            append-to-body
          >
            {{ column.label }}
          </b-tooltip>
        </template>
        <template v-slot="props">
          {{ props.row.additional_price }}
        </template>
      </b-table-column>
      <b-table-column label="Total Price" numeric v-slot="props">
        {{ props.row.total_price }}
      </b-table-column>
      <b-table-column label="Edit" v-slot="props">
        <b-button
          type="is-dark"
          icon-right="pen"
          size="is-small"
          outlined
          @click="
            [
              $emit('edit-piece', props.row),
              ($store.state.checkoutSidebarOpen = false),
            ]
          "
        />
      </b-table-column>
      <b-table-column label="Delete" v-slot="props">
        <b-button
          type="is-dark"
          icon-right="trash"
          size="is-small"
          outlined
          @click="$emit('remove-from-cart', props.row)"
        />
      </b-table-column>
      <template v-slot:detail="props">
        <ul>
          Prelude
          <li
            v-for="(part, index) in props.row.parts.prelude"
            :key="`${props.row.title}_prelude_${index}`"
            class="part-title"
          >
            <span v-html="part.title"></span>
          </li>
        </ul>
        <ul>
          Fugue
          <li
            v-for="(part, index) in props.row.parts.fugue"
            :key="`${props.row.title}_fugue_${index}`"
            class="part-title"
          >
            <span v-html="part.title"></span>
          </li>
        </ul>
        <span>Score: {{ props.row.score ? 'Included' : 'Not included' }}</span>
      </template>
      <template v-slot:footer>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th><div class="th-wrap is-numeric">Grand Total:</div></th>
        <th>
          <div class="th-wrap is-numeric">{{ grandTotal | intToCurrency }}</div>
        </th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'CheckoutList',
  props: {
    cart: Array,
    compact: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // tableData: this.getTableData(),
      // voices: ['fugue', 'prelude'],
    };
  },
  computed: {
    tableData() {
      return this.getTableData();
    },
    grandTotal() {
      let totals = [];
      this.cart.forEach(function (item) {
        totals.push(item.price.total);
      });
      let sum = totals.reduce((a, b) => a + b, 0);
      return sum;
    },
  },
  methods: {
    toggle: function (row) {
      this.$refs.table.toggleDetails(row);
    },
    getTableData: function () {
      const cart = this.cart;
      const finalData = [];
      const vue = this;

      cart.forEach((cartItem, index) => {
        // construct the title of the piece
        const title = `Book ${vue.$options.filters.numberToRoman(
          cartItem.piece.book
        )}: No. ${cartItem.piece.no} in ${vue.$options.filters.keySymbols(
          cartItem.piece.key
        )} (BWV ${cartItem.piece.bwv})`;

        // gather all of parts by movement, check price, etc
        const parts = { prelude: [], fugue: [] };
        Object.keys(parts).forEach((movement) => {
          const additionalCheck = {};
          for (let i = 1; i <= cartItem.piece[movement].type; i++) {
            additionalCheck[i] = false;
          }

          cartItem.selectedParts[movement].forEach((selectedPart) => {
            parts[movement].push({
              id: selectedPart.id,
              title: `<strong>Part ${vue.$options.filters.numberToRoman(
                selectedPart.part
              )}:</strong> ${this.getInstrumentNames(
                selectedPart.instruments
              ).join(', ')}`,
              // price: addlPrice,
            });
          });
        });

        finalData.push({
          index: index,
          id: cartItem.piece.id,
          title: title,
          base_price: vue.$options.filters.intToCurrency(
            cartItem.price.basePrice
          ),
          additional_price: vue.$options.filters.intToCurrency(
            this.getAdditionalPrice(cartItem)
          ),
          original_price: vue.$options.filters.intToCurrency(
            cartItem.originalPrice.basePrice
          ),
          total_price: vue.$options.filters.intToCurrency(cartItem.price.total),
          parts: parts,
          score: cartItem.score,
        });
      });
      return finalData;
    },
    getAdditionalPrice: function (cartItem) {
      // if purchasing disperate parts with a score (e.g. not a set)
      if (cartItem.score && cartItem.price.basePrice === 0) {
        return cartItem.price.additionalParts + cartItem.price.scorePrice;
      }
      // otherwise just need the additional parts
      return cartItem.price.additionalParts;
    },
    getInstrumentNames: function (instrumentList) {
      return instrumentList.map((instrumentAbbrev) => {
        const fullInstrument = this.$store.getters.instruments.find(
          (instrument) => instrument.id === instrumentAbbrev
        );
        const displayName =
          this.$options.filters.displayInstrumentName(fullInstrument);
        return displayName;
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/sass/styles';
#checkout_list {
  padding: $gap 0;
}
li.part-title {
  margin-left: 12px;
}
</style>
