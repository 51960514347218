import axios from 'axios';
import Pricing from '@/mixins/Pricing';

export default {
  mixins: [Pricing],
  data() {
    return {
      quoteId: undefined,
      cartMask: undefined,
    };
  },
  async mounted() {
    this.MAGENTO = axios.create({
      baseURL: this.baseApi,
    });

    // const test = await axios.get('/.netlify/functions/getProduct', {
    //   headers: {
    //     Accept: 'application/json',
    //   },
    //   params: {
    //     sku: 'O21X',
    //   },
    // });
    // console.log(test.data);
  },
  methods: {
    // The main cart function
    doCartActions: async function () {
      await this.makeNewCart();

      for (const product of this.$store.state.userCart) {
        const productFiles = [];
        if (product.selectedParts) {
          productFiles.push(...this.getNeededPartsFiles(product));
        }
        if (product.score) {
          productFiles.push(product.piece.score.pdf_file);
        }

        const productData = await this.getItemData(product.piece.sku);
        const itemInCart = await this.addToCart(productData, productFiles);
        await this.setItemCartPrice(
          itemInCart.data.item_id,
          product.price.total / 100 /* Mage needs normal dollars not cents */
        );
      }
      return this.quoteId;
    },

    // make a new cart in mage
    makeNewCart: async function () {
      const { data: cartMask } = await axios.get(
        '/.netlify/functions/mageCreateCartMask'
      );
      // console.log('the cart mask', cartMask);
      this.cartMask = cartMask;

      const cart = await axios.get('/.netlify/functions/mageCreateCart', {
        params: {
          mask: this.cartMask,
        },
      });

      this.quoteId = cart.data.id;
      return cartMask.data;
    },

    // add a product to cart
    addToCart: async function (productData, productFiles) {
      const downloadableLinks = this.getDownloadableLinks(
        productData,
        productFiles
      );
      const cartPost = this.formatQuotePost(productData, downloadableLinks);
      const cartData = await this.addItemToQuote(cartPost);
      return cartData;
    },

    // sets the item in the cart to a custom price
    setItemCartPrice: async function (sku, price) {
      const res = await axios.post('/.netlify/functions/mageSetProductPrice', {
        quoteId: this.quoteId,
        productId: sku,
        price: price,
      });
      return res;
    },

    // queries magento for needed data about the product
    getItemData: async function (sku) {
      const itemDataRes = await axios.get(
        '/.netlify/functions/mageGetProduct',
        {
          params: {
            sku: sku,
          },
          headers: {
            Accept: 'application/json',
          },
        }
      );

      // console.log(itemDataRes);
      return itemDataRes.data;
    },

    // returns string[] with file names
    getNeededPartsFiles: function (product) {
      const parts = [];
      Object.keys(product.selectedParts).forEach((mvmt) => {
        product.selectedParts[mvmt].forEach((part) => parts.push(part.file));
      });
      return parts;
    },

    // ========================================================================
    // ===============================HELPERS==================================
    // ========================================================================

    // matches up file names with mage downloadable links
    getDownloadableLinks: function (productData, productFiles) {
      const digitalLinks =
        productData.extension_attributes.downloadable_product_links;

      const digitaLinksFiltered = productFiles.map((file) => {
        return digitalLinks.find(
          (link) => file === link.extension_attributes.link_digital_ocean_key
        );
      });

      return digitaLinksFiltered;
    },

    // formats the post body for addToQuote call
    formatQuotePost: function (productData, downloadableLinks) {
      const downloadableLinkIds = downloadableLinks.map((link) => link.id);
      const data = {
        cartItem: {
          qty: 1,
          sku: productData.sku,
          quote_id: `${this.cartMask}`, // yes the cartMask goes here even though it's not the quote id something about guest carts etc...
          product_option: {
            extension_attributes: {
              downloadable_option: {
                downloadable_links: [...downloadableLinkIds],
              },
            },
          },
        },
      };
      return data;
    },

    // adds the item to quote
    addItemToQuote: async function (cartPostData) {
      try {
        const res = await axios.post('/.netlify/functions/mageAddItemToCart', {
          mask: this.cartMask,
          postData: cartPostData,
        });
        return res;
      } catch (err) {
        console.log('the post error', err);
      }
    },
  },
};
