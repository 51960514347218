<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <img :src="fullLogo" :alt="fullLogoAlt" />
      <h2 class="subtitle is-uppercase">
        {{ slogan }}
      </h2>
      <p>
        {{ copyright }}
      </p>
    </div>
  </footer>
</template>

<script>
import Config from '@/config';
const { slogan, copyright, fullLogo, fullLogoAlt } = Config;

export default {
  name: 'FooterBar',
  data: () => ({
    slogan,
    copyright,
    fullLogo,
    fullLogoAlt,
  }),
};
</script>

<style lang="scss" scoped>
@import '@/sass/styles';
.content,
h2.subtitle {
  color: $white;
}

.footer {
  margin-top: 1.5rem;
}
</style>
