<template>
  <nav class="cf-header">
    <div class="clear"></div>
    <div class="cf-slogan is-uppercase">{{ slogan }}</div>
    <div class="cf-logo-wrapper">
      <a href="https://carlfischer.com/">
        <img class="cf-logo" :src="logoPath" :alt="logoAlt" />
      </a>
    </div>
  </nav>
</template>

<script>
import Config from '@/config';
const { slogan, logoPath, logoAlt } = Config;
export default {
  name: 'Header',
  data: () => {
    return {
      menuColor: `is-black`,
      showMenu: false,
      slogan,
      logoPath,
      logoAlt,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/styles';

.cf-header {
  background-color: $primary;
  padding: 10px;
  margin-bottom: 0 !important;
  display: grid;
  grid-template-columns: 1fr auto 1fr;

  .clear {
    grid-column: 1;
  }

  .cf-slogan {
    color: $white;
    grid-column: 2;

    @include touch {
      display: none;
    }
  }

  .cf-logo-wrapper {
    grid-column: 3;

    .cf-logo {
      max-height: 22px;
      float: right;
    }
  }
}
</style>
