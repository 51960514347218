

















import Vue from 'vue';

interface Breadcrumb {
  name: string;
  link?: string;
}

export default Vue.extend({
  name: 'Breadcrumb',
  props: {
    list: {
      type: Array as () => Breadcrumb[],
    },
  },
  methods: {
    formatBreadcrumbName(name: string | Function): string {
      return typeof name === 'function' ? name(this.$route) : name;
    },
  },
});
