<template>
  <div>
    <h1 class="title">Error 404</h1>
    <p>Looks like this page doesn't exist.</p>
  </div>
</template>

<script>
export default {
  name: '404',
};
</script>

<style scoped></style>
