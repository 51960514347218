import Parts from '@/mixins/Parts';

export default {
  mixins: [Parts],
  data() {
    return {
      basePrices: {
        3: 999,
        4: 1499,
        5: 1999,
      },
      originalPrices: {
        3: 1999,
        4: 2499,
        5: 2999,
      },
      additionalPartPrice: 600,
      scorePrice: 1000,
    };
  },
  methods: {
    // ========================================================================
    // =========================Logic for card view============================
    // ========================================================================
    isEnsembleReady(piece) {
      return (
        this.$store.state.ensembleReady[piece.prelude.type] &&
        this.$store.state.ensembleReady[piece.fugue.type]
      );
    },
    calculatePrice(piece) {
      const ensembleReady = this.isEnsembleReady(piece);

      // TODO: don't even call this function at the card level if not valid ensemble
      if (ensembleReady) {
        const largestEnsemble = Math.max(piece.prelude.type, piece.fugue.type);
        // This is the like checking the parts in the parts list
        const filteredParts = this.getFilteredPartsByEnsemble(
          piece,
          this.$store.state.userEnsembleSorted
        );

        const totalInstrumentParts = filteredParts.both
          ? filteredParts.both.length * 2
          : filteredParts.prelude.length + filteredParts.fugue.length;

        // Divide by 2 and round up because you get 2 instrument parts per check (one for prelude and one for fugue)
        const numberOfAdditionalParts =
          largestEnsemble >= Math.ceil(totalInstrumentParts / 2)
            ? 0
            : Math.ceil(totalInstrumentParts / 2) - largestEnsemble;

        const additionalPartsPrice =
          numberOfAdditionalParts * this.additionalPartPrice;

        return {
          total: this.basePrices[largestEnsemble] + additionalPartsPrice,
          basePrice: this.basePrices[largestEnsemble],
          additionalParts: numberOfAdditionalParts,
          additionalPartPrice: additionalPartsPrice,
        };
      }
      return {
        total: false,
        basePrice: false,
        additionalParts: false,
        additionalPartPrice: false,
      };
    },

    // ========================================================================
    // =========================Logic for custom parts=========================
    // ========================================================================
    calculateCustomPrice(piece, checkedRows, scoreChecked = true) {
      const largestEnsemble = Math.max(piece.prelude.type, piece.fugue.type);

      // check if there's one checked from every part and the score is checked.
      // if true we can calculate based of the set, else just add everything up.
      const applySetPricing =
        this.isPieceFullyChecked(checkedRows, piece) && scoreChecked;

      let pricing;
      if (applySetPricing) {
        pricing = this.calculateSetPricing(piece, checkedRows, largestEnsemble);
      } else {
        pricing = this.calculateNonSetPricing(checkedRows, largestEnsemble);
        pricing.scorePrice = scoreChecked ? this.scorePrice : 0;
        pricing.total = pricing.additionalParts + pricing.scorePrice;
      }
      return pricing;
    },

    calculateOriginalCustomPrice(piece, checkedRows, scoreChecked = true) {
      const largestEnsemble = Math.max(piece.prelude.type, piece.fugue.type);

      // check if there's one checked from every part and the score is checked.
      // if true we can calculate based of the set, else just add everything up.
      const applySetPricing =
        this.isPieceFullyChecked(checkedRows, piece) && scoreChecked;

      let pricing;
      if (applySetPricing) {
        pricing = this.calculateOriginalSetPricing(piece, checkedRows, largestEnsemble);
      } else {
        pricing = this.calculateNonSetPricing(checkedRows, largestEnsemble);
        pricing.scorePrice = scoreChecked ? this.scorePrice : 0;
        pricing.total = pricing.additionalParts + pricing.scorePrice;
      }
      return pricing;
    },

    isPieceFullyChecked(checkedRows, piece) {
      // push all the part selctions into an array [1,1,1,2,2,2,3,3,3,4,4,4] where
      const checkedPartsPrelude = checkedRows?.prelude?.map((row) => row.part);
      const checkedPartsFugue = checkedRows?.fugue?.map((row) => row.part);

      // dedup the parts, we need to know if theres a selection for each part. at least one selection per part [1,2,3,4]
      const checkedPartsUniquePrelude = [...new Set(checkedPartsPrelude)];
      const checkedPartsUniqueFugue = [...new Set(checkedPartsFugue)];

      // if the parts selection matches the sume of the types, we have a fully checked situatiuon
      return (
        checkedPartsUniquePrelude.length + checkedPartsUniqueFugue.length ===
        piece.prelude.type + piece.fugue.type
      );
    },

    calculateSetPricing(piece, checkedRows, largestEnsemble) {
      // set pricing covers the number of parts for the largest prelude/fugue, everything else is extra
      const numberOfExtraParts = this.getExtraPartsCount(checkedRows, piece);
      const extraPartsPrice = numberOfExtraParts * this.additionalPartPrice;

      // TODO: reconcile which logic works better
      // const totalParts = this.calculateTotalParts(checkedRows, largestEnsemble);
      // const numberOfExtraParts = totalParts - largestEnsemble;

      return {
        basePrice: this.basePrices[largestEnsemble],
        additionalParts: extraPartsPrice,
        additionalPartsCount: numberOfExtraParts,
        total: this.basePrices[largestEnsemble] + extraPartsPrice,
      };
    },

    calculateOriginalSetPricing(piece, checkedRows, largestEnsemble) {
      // set pricing covers the number of parts for the largest prelude/fugue, everything else is extra
      const numberOfExtraParts = this.getExtraPartsCount(checkedRows, piece);
      const extraPartsPrice = numberOfExtraParts * this.additionalPartPrice;

      // TODO: reconcile which logic works better
      // const totalParts = this.calculateTotalParts(checkedRows, largestEnsemble);
      // const numberOfExtraParts = totalParts - largestEnsemble;

      return {
        basePrice: this.originalPrices[largestEnsemble],
        additionalParts: extraPartsPrice,
        additionalPartsCount: numberOfExtraParts,
        total: this.originalPrices[largestEnsemble] + extraPartsPrice,
      };
    },

    calculateNonSetPricing(checkedRows, largestEnsemble) {
      const totalParts = this.calculateTotalParts(checkedRows, largestEnsemble);

      return {
        basePrice: 0,
        additionalPartsCount: totalParts,
        additionalParts: totalParts * this.additionalPartPrice,
      };
    },

    calculateTotalParts: function (checkedRows, largestEnsemble) {
      // gather the checkmarks by voice [1,1,1,2,2,2,3,3]
      const checkedPartsPrelude = checkedRows.prelude.map((row) => {
        return row.part;
      });
      const checkedPartsFugue = checkedRows.fugue.map((row) => {
        return row.part;
      });

      // count up the parts, determine how many parts need to be charged
      const countPartByVoice = { prelude: {}, fugue: {} };
      const saleablePartsCount = {};
      for (let index = 1; index <= largestEnsemble; index++) {
        // count all the prelude checks
        countPartByVoice.prelude[index] = 0;
        checkedPartsPrelude.forEach((part) => {
          if (index === part) {
            countPartByVoice.prelude[index] = countPartByVoice.prelude[index]
              ? countPartByVoice.prelude[index] + 1
              : 1;
          }
        });
        // then count all the fugue checks
        countPartByVoice.fugue[index] = 0;
        checkedPartsFugue.forEach((part) => {
          if (index === part) {
            countPartByVoice.fugue[index] = countPartByVoice.fugue[index]
              ? countPartByVoice.fugue[index] + 1
              : 1;
          }
        });
        // which ever has more for that voice we charge that many
        saleablePartsCount[index] = Math.max(
          countPartByVoice.prelude[index],
          countPartByVoice.fugue[index]
        );
      }

      const totalParts = Object.keys(saleablePartsCount).reduce(
        (count, voice) => count + saleablePartsCount[voice],
        0
      );

      return totalParts;
    },

    getRemainingFreeParts(checkedRows) {
      const weakSideCount = Math.min(
        checkedRows.prelude.length,
        checkedRows.fugue.length
      );
      const weakSide =
        checkedRows.prelude.length === weakSideCount ? 'prelude' : 'fugue';
      const diff = Math.abs(
        checkedRows.prelude.length - checkedRows.fugue.length
      );

      return diff > 0
        ? {
            diff,
            weakSide,
          }
        : {};
    },

    getExtraPartsCount(checkedRows, piece) {
      const largestEnsemble = Math.max(piece.prelude.type, piece.fugue.type);
      const strongSideCount = Math.max(
        checkedRows.prelude.length,
        checkedRows.fugue.length
      );

      /**
       * We care about the side with the most checked rows.
       * After the set, what counts as extra will always be the side with the most checked.
       * I.e. for a set of prelude(quintet) and fugue(trio) =>
       * we don't start caring about what's checked on the fugue until it surpasses 5 or however many are checked on the prelude side.
       */
      return strongSideCount - largestEnsemble;
    },
  },
};
