<template>
  <section class="checkout section">
    <p>
      Let's review! On confirm, you will be redirected to our online store at
      carlfischer.com to complete the checkout process.
    </p>
    <CheckoutList
      :cart="cart"
      :compact="compact"
      @remove-from-cart="removeAlert"
      @edit-piece="editPiece"
    ></CheckoutList>
    <div class="buttons is-grouped is-right" :class="{ compact }">
      <p class="control">
        <b-button
          class="checkout-action"
          type="is-danger"
          icon-left="trash"
          @click="clearCart"
          outlined
          :disabled="!(cart.length > 0)"
          >Clear Cart</b-button
        >
      </p>
      <p class="control">
        <b-tooltip
          class="checkout-action-tooltip"
          label="You will be redirected to our online store at carlfischer.com to process payment."
          :position="compact ? 'is-top' : 'is-bottom'"
          type="is-dark"
          multilined
        >
          <b-button
            class="checkout-action"
            type="is-success"
            outlined
            icon-left="cash-register"
            @click="sendToStore"
            :disabled="!(cart.length > 0)"
            >Let's Checkout!</b-button
          >
        </b-tooltip>
      </p>
    </div>
  </section>
</template>

<script>
//TODO: Build out the edge case where user wants to buy extra parts only, without the base score.

import CheckoutList from '@/components/Checkout/CheckoutList.vue';
import MagentoIntegration from '@/mixins/MagentoIntegration.js';
import $eventHub from '@/components/EventHub';

export default {
  name: 'Checkout',
  components: {
    CheckoutList,
  },
  mixins: [MagentoIntegration],
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cart() {
      return this.$store.getters.userCart;
    },
  },
  methods: {
    sendToStore: async function () {
      console.log('sending cart data to the store for purchase');
      this.$store.state.checkoutSidebarOpen = false;
      try {
        this.startLoading();
        const cartId = await this.doCartActions();

        // console.log(
        //   `https://staging.carlfischer.com/quote/cart/redirect?quote_id=${cartId}`
        // );

        window.location.href = `${process.env.VUE_APP_MAGENTO_REDIRECT}?quote_id=${cartId}`;
      } catch (e) {
        console.log(e);
        this.stopLoading();

        this.$buefy.dialog.alert({
          title: 'Error',
          message:
            'Something went wrong! Please try again later or contact support at sales@carlfischer.com',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        });
      }
    },
    startLoading: function () {
      $eventHub.$emit('componentLoading');
    },
    stopLoading: function () {
      $eventHub.$emit('componentLoaded');
    },
    clearCart: function () {
      this.$store.commit('clearCart');
    },
    editPiece: function (piece) {
      //send the user back to the piece in question so they can edit the selection.
      let pieces = this.$store.getters.pieces;
      let foundPiece = pieces.filter(function (val) {
        return val.id == piece.id;
      })[0];
      let route = {
        name: 'Piece',
        params: {
          bwv: foundPiece.bwv,
          checkedCartIndex: piece.index,
        },
      };
      this.$router.push(route);
      //TODO: maybe make a spinner and delay here?
    },
    removeAlert: function (piece) {
      this.$buefy.dialog.confirm({
        title: 'Confirm Remove from Cart',
        message:
          'Are you sure you want to remove this piece from your cart?<br /><br />' +
          piece.title,
        confirmText: 'Remove from Cart',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.commit('removeFromCart', piece.index);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/styles';

.checkout {
  display: flex;
  flex-flow: column;
}

.compact {
  margin-top: auto;

  .control,
  .checkout-action,
  .checkout-action-tooltip {
    width: 100%;
  }
}

@include touch {
  .control,
  .checkout-action,
  .checkout-action-tooltip {
    width: 100%;
  }
}
</style>
