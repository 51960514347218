<template>
  <div class="has-text-centered">
    <img
      class="jsb-logo"
      :src="jsbLogoPath"
      :alt="jsbLogoAlt"
      @load="logoLoaded"
    />
  </div>
</template>

<script>
import Config from '@/config';
import $eventHub from '@/components/EventHub';
const { jsbLogoPath, jsbLogoAlt } = Config;

export default {
  name: 'JSB',
  data: () => ({
    jsbLogoPath,
    jsbLogoAlt,
  }),
  methods: {
    logoLoaded() {
      $eventHub.$emit('jsbLoaded');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/styles';

.jsb-logo {
  max-height: 25vh !important;
}
</style>
