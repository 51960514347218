import Vue from 'vue';
import Router from 'vue-router';
import $eventHub from '@/components/EventHub';

Vue.use(Router);

// const base = process.env.NODE_ENV === 'development' ? '/dev/' : '/';

const router = new Router({
  mode: 'history',
  // base,

  routes: [
    {
      path: '/',
      name: 'How It Works',
      component: () => import('@/pages/HowItWorks.vue'),
      meta: {
        breadcrumb: [{ name: 'Home', link: '/' }, { name: 'How It Works' }],
      },
    },
    {
      path: '/see-in-action',
      name: 'See in Action',
      component: () => import('@/pages/HowItWorks.vue'),
      meta: {
        breadcrumb: [{ name: 'Home', link: '/' }, { name: 'How It Works' }],
        youtube: true,
      },
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('@/pages/About.vue'),
      meta: {
        breadcrumb: [{ name: 'Home', link: '/' }, { name: 'About' }],
      },
    },
    {
      path: '/build',
      redirect: { name: 'Choose Ensemble' },
      name: 'Build',
      component: () => import('@/pages/Build.vue'),
      children: [
        {
          path: 'ensemble',
          name: 'Choose Ensemble',
          meta: {
            breadcrumb: [
              { name: 'Home', link: '/' },
              { name: 'Build Your Ensemble' },
            ],
          },
        },
        {
          path: 'pieces',
          name: 'Choose Pieces',
          meta: {
            breadcrumb: [{ name: 'Home', link: '/' }, { name: 'Pieces' }],
          },
        },
        {
          path: 'finalize',
          name: 'Finalize',
          meta: {
            breadcrumb: [{ name: 'Home', link: '/' }, { name: 'Finalize' }],
          },
        },
      ],
    },
    {
      path: '/bwv/:bwv',
      name: 'Piece',
      component: () => import('@/pages/Piece.vue'),
      meta: {
        breadcrumb: [
          { name: 'Home', link: '/' },
          { name: 'Pieces', link: '/build/pieces' },
          { name: (route) => `BWV ${route.params.bwv}` },
        ],
      },
    },
    {
      path: '*',
      name: '404*',
      component: require('@/pages/404.vue').default, // load sync home
    },
  ],
  scrollBehavior(_to, _from, savedPosition) {
    // native-like behavior when navigating with back/forward buttons
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((_to, _from, next) => {
  $eventHub.$emit('componentLoading', _to);
  document.title =
    `Well-Tempered Consort | ${getPageTitle(_to)}` || 'Well-Tempered Consort';
  next();
});

function getPageTitle(_to) {
  if (_to.name === 'Piece') {
    return `BWV ${_to.params.bwv}`;
  }
  return _to.name;
}

router.afterEach(() => {
  $eventHub.$emit('componentLoaded');
});

export default router;
