import { getInitState } from './initState';

//TODO: Remove all console.log!
export function calculateEnsembleSorted(state) {
  let userEnsemble = state.userEnsemble;
  let partsSkeleton = state.parts;
  let selectedSort = getInitState('userEnsembleSorted');

  // We need to find two things:
  // 1) The Size of Ensemble which matches.
  // 2) The Part Number in that Ensemble.

  //Take each picked instrument in turn and place it in the sorter.
  userEnsemble.forEach(function (instrument) {
    //Go one ensemble size at a time.
    partsSkeleton.forEach(function (ensemble) {
      let foundMatches = [];
      //Determine the part numbers. If the instrument priority is negative, then start at the *bottom* of the list.
      let loopList = [];
      for (let i = 1; i <= ensemble.count; i++) {
        loopList.push(i);
      }
      let priority = instrument.priority[ensemble.count] ?? instrument.priority;
      if (priority < 0) {
        loopList.reverse();
      }
      //Now, loop through the parts.
      loopList.forEach(function (partNum) {
        //The instrument within the part
        for (
          let instIndex = 0;
          instIndex < ensemble.parts[partNum].length;
          instIndex++
        ) {
          // console.log('in the loop', ensemble.parts[partNum][instIndex]);
          if (ensemble.parts[partNum][instIndex] === instrument.id) {
            //Calculate the existing count. If this instrument has already been placed in this position, keep looking.
            let existingCheck = selectedSort[ensemble.count][partNum].filter(
              (inst) => inst['id'] === instrument.id
            );
            let lclMatch = {
              ensemble: ensemble.count,
              part: partNum,
              existingCount: existingCheck.length,
            };
            foundMatches.push(lclMatch);
          }
        }
      });
      //Now, process the matches.
      if (foundMatches.length !== 0) {
        //If there are multiple matches and matches that match a part that's already been occupied, eliminate that as an option.
        if (foundMatches.length > 1) {
          let conflictMatch = [],
            otherMatch = [];
          for (let i = 0; i < foundMatches.length; i++) {
            let lclArr =
              selectedSort[foundMatches[i].ensemble][foundMatches[i].part];
            if (lclArr === undefined || lclArr.length == 0) {
              otherMatch.push(foundMatches[i]);
            } else {
              conflictMatch.push(foundMatches[i]);
            }
          }
          foundMatches = otherMatch.concat(conflictMatch);
        }
        //Use the match that has the fewest existing counts. If they are equal, preserve the order.
        foundMatches.sort(function (a, b) {
          if (a.existingCount < b.existingCount) {
            return -1;
          }
          if (a.existingCount > b.existingCount) {
            return 1;
          }
          return 0;
        });

        //Pick the first match here.
        selectedSort[foundMatches[0].ensemble][foundMatches[0].part].push(
          instrument
        );
      }
    });
  });

  //Now, check if there's any that are incomplete and if we can shuffle instruments around to cause them to be complete.
  partsSkeleton.forEach(function (ensemble) {
    if (userEnsemble.length < ensemble.count) {
      return;
    }
    let badParts = [];
    let partsWithMultiples = [];
    Object.entries(selectedSort[ensemble.count]).forEach((entry) => {
      const [index, part] = entry;
      if (part.length === 0) {
        badParts.push(index);
      }
      if (part.length > 1) {
        partsWithMultiples.push(index);
      }
    });
    if (badParts.length === 0) {
      return;
    }

    //Now go thru the parts with multiples and see if it is possible to move one of those instruments to the bad part.
    partsWithMultiples.forEach(function (partIndex) {
      let lclInsts = selectedSort[ensemble.count][partIndex];
      badParts.forEach(function (badPartIndex) {
        let lclSkeleton = ensemble.parts[badPartIndex];
        //if any of the lcl insts are in the skeleton, then we can move something!
        lclInsts.forEach(function (inst, instIndex) {
          if (lclSkeleton.includes(inst.id)) {
            //Push the inst to the bad part.
            selectedSort[ensemble.count][badPartIndex].push(inst);
            //Delete the old inst.
            delete selectedSort[ensemble.count][partIndex][instIndex];
            let oldInsts = selectedSort[ensemble.count][partIndex];
            selectedSort[ensemble.count][partIndex] = [];
            //Reset indexes on the old spot.
            oldInsts.forEach(function (oldInst) {
              selectedSort[ensemble.count][partIndex].push(oldInst);
            });
          }
        });
      });
    });
    console.log('the final select sort', selectedSort);
  });

  return selectedSort;
}

export function checkEnsembleReady(state) {
  const selectedSort = state.userEnsembleSorted;
  const ensembleReady = getInitState('ensembleReady');

  // for each size (duet, trio, etc)
  for (let i = 2; i <= 5; i++) {
    let partValid = 0;
    // for each part (part I, part II, etc)
    for (let j = 1; j <= i; j++) {
      if (selectedSort[i][j].length > 0) {
        partValid += 1;
      }
    }
    // if there's at least one of every part for that size
    if (partValid === i) ensembleReady[i] = true;
  }
  return ensembleReady;
}
