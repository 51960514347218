<template>
  <b-navbar>
    <template slot="brand">
      <b-navbar-item class="brand-item" tag="router-link" :to="{ path: '/' }">
        <img class="wtc-logo" :src="wtcLogoPath" :alt="wtcLogoAlt" />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item tag="router-link" :to="{ name: 'How It Works' }">
        How It Works
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ name: 'About' }">
        About
      </b-navbar-item>
      <b-navbar-dropdown label="Get Started">
        <b-navbar-item tag="router-link" :to="{ name: 'Choose Ensemble' }">
          Build Your Ensemble
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'Choose Pieces' }">
          Choose Your Pieces
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'Finalize' }">
          Finalize Your Configuration
        </b-navbar-item>
      </b-navbar-dropdown>
    </template>
    <template slot="end">
      <b-navbar-item tag="div">
        <div class="buttons">
          <!--
          <router-link class="button is-primary" :to="{ name: 'Finalize' }">
            <b-icon icon="shopping-cart" size="is-small"></b-icon>
            <strong>Configuration</strong>
          </router-link>
          -->
          <button @click="toggleCartSidebar" class="button is-primary">
            <b-icon icon="shopping-cart" size="is-small"></b-icon>
            <strong>Cart</strong>
          </button>
          <button @click="toggleEnsembleSidebar" class="button is-primary">
            <span>Your Ensemble</span>
          </button>
        </div>
      </b-navbar-item>
      <b-sidebar
        type="is-light"
        fullheight
        overlay
        right
        v-model="ensembleSidebarOpen"
      >
        <h1 class="title">Your Ensemble</h1>
        <template v-if="userEnsemble.length === 0">
          <span
            >No ensemble is selected. Head to
            <router-link :to="{ name: 'Build' }" @click.native="open = false">
              Build Your Ensemble
            </router-link>
            first.</span
          >
        </template>
        <template v-else>
          <ul id="user-ensemble-nav">
            <li v-for="(ensItem, idx) in userEnsemble" :key="idx">
              <span
                v-html="$options.filters.displayInstrumentName(ensItem)"
              ></span>
            </li>
          </ul>
        </template>
        <b-button
          class="reset-ensemble"
          type="is-danger"
          icon-left="minus-circle"
          outlined
          :disabled="userEnsemble.length === 0"
          @click="alertDelete"
        >
          Reset Ensemble
        </b-button>
      </b-sidebar>
      <b-sidebar
        type="is-light"
        overlay
        fullheight
        right
        v-model="checkoutSidebarOpen"
      >
        <div class="side-checkout">
          <h1 class="title" @click="checkoutSidebarOpen = false">
            <router-link to="/build/finalize">Your Cart</router-link>
          </h1>
          <Checkout class="full-height" compact />
        </div>
      </b-sidebar>
    </template>
  </b-navbar>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMinusCircle, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Config from '@/config';
import Checkout from '@/components/Checkout/Checkout';
import $eventHub from '@/components/EventHub';
const { wtcLogoPath, wtcLogoAlt } = Config;

library.add(faMinusCircle, faCaretDown);

export default {
  name: 'NavBar',
  components: {
    Checkout,
  },
  mounted() {
    $eventHub.$on('cartChange', this.toggleCartSidebar);
  },
  computed: {
    userEnsemble() {
      return this.$store.getters.userEnsemble;
    },
    ensembleSidebarOpen: {
      get: function () {
        return this.$store.state.ensembleSidebarOpen;
      },
      set: function (value) {
        this.$store.state.ensembleSidebarOpen = value;
      },
    },
    checkoutSidebarOpen: {
      get: function () {
        return this.$store.state.checkoutSidebarOpen;
      },
      set: function (value) {
        this.$store.state.checkoutSidebarOpen = value;
      },
    },
  },
  data() {
    return {
      wtcLogoPath,
      wtcLogoAlt,
    };
  },
  methods: {
    toggleCartSidebar() {
      this.checkoutSidebarOpen = true;
    },
    toggleEnsembleSidebar() {
      this.ensembleSidebarOpen = true;
    },
    alertDelete: function () {
      this.$buefy.dialog.confirm({
        title: 'Confirm Reset',
        message: 'Are you sure you want to reset your ensemble?',
        confirmText: 'Reset Ensemble',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: this.resetEnsemble,
      });
    },
    resetEnsemble: function () {
      this.$store.commit('updateUserEnsembleId', null);
      this.$store.commit('resetUserEnsemble');
      this.$store.commit('resetUserEnsembleSorted');
      this.$store.commit('resetEnsembleReady');
      this.userEnsembleId = null;
      this.$buefy.toast.open('Ensemble Reset!');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/styles';

h1 a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
}

.wtc-logo {
  max-height: $size-1 !important;
}

.brand-item {
  padding: 1rem 0.75rem;
}

.reset-ensemble {
  margin-top: auto;
}

.side-checkout {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.full-height {
  flex: 1;
}
</style>

<style lang="scss">
@import '@/sass/styles';
.navbar-burger {
  margin-top: auto;
  margin-bottom: auto;
}

.sidebar-content {
  padding: $gap / 2;

  .section {
    padding: 0;
  }
}
</style>
