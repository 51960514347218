export default {
  methods: {
    getFilteredPartsByEnsemble(piece, sortedEnsemble) {
      if (!sortedEnsemble) return [];

      const { prelude, fugue, parts } = piece;
      const sortedPreludeParts =
        sortedEnsemble && prelude?.type && sortedEnsemble[prelude.type];
      const sortedFugueParts =
        sortedEnsemble && fugue?.type && sortedEnsemble[fugue.type];
      // piece.parts is simply an array so we specify prelude.type since prelude will always be defined where parts is defined
      const sortedParts = sortedPreludeParts || sortedFugueParts;

      // Select parts if they have the corresponding part number and instruments
      const newFilteredPreludeParts =
        sortedPreludeParts &&
        prelude?.parts &&
        prelude.parts.filter((part) => {
          const sortedPreludeIds =
            sortedPreludeParts[part.part]?.map((part) => part.id) ?? [];
          return part.instruments.some((instrument) =>
            sortedPreludeIds.includes(instrument)
          );
        });

      const newFilteredFugueParts =
        sortedFugueParts &&
        fugue?.parts &&
        fugue.parts.filter((part) => {
          const sortedFugueIds =
            sortedFugueParts[part.part]?.map((part) => part.id) ?? [];
          return part.instruments.some((instrument) =>
            sortedFugueIds.includes(instrument)
          );
        });

      const newFilteredParts =
        sortedParts &&
        parts &&
        parts.filter((part) => {
          const sortedIds =
            sortedParts[part.part]?.map((part) => part.id) ?? [];
          return part.instruments.some((instrument) =>
            sortedIds.includes(instrument)
          );
        });

      return {
        prelude: newFilteredPreludeParts && newFilteredPreludeParts,
        fugue: newFilteredFugueParts && newFilteredFugueParts,
        both: newFilteredParts && newFilteredParts,
      };
    },
    allPartsValid(piece, sortedEnsemble) {
      // Short circuit if no ensemble defined
      if (sortedEnsemble.length === 0) return false;
      const { prelude, fugue } = piece;

      // Another short circuit by checking ensemble length compatibility
      if (
        sortedEnsemble.length < prelude.type ||
        sortedEnsemble.length < fugue.type
      )
        return false;

      // Check if we can check all parts
      const {
        prelude: filteredPreludeParts,
        fugue: filteredFugueParts,
        both: filteredAllParts,
      } = this.getFilteredPartsByEnsemble(piece, sortedEnsemble);

      // compare length of unique parts in filtered parts to intended prelude/fugue ensemble length
      const preludeMatches = filteredPreludeParts
        ? [...new Set(filteredPreludeParts.map((p) => p.part))].length >=
          prelude.type
        : true;
      const fugueMatches = filteredFugueParts
        ? [...new Set(filteredFugueParts.map((p) => p.part))].length >=
          fugue.type
        : true;
      // prelude and fugue will have the same type
      const preludeAndFugueMatches = filteredAllParts
        ? [...new Set(filteredAllParts.map((p) => p.part))].length >=
          prelude.type
        : true;

      return preludeMatches && fugueMatches && preludeAndFugueMatches;
    },
    stringifyPart(part) {
      return `<strong>Part ${this.$options.filters.numberToRoman(
        part.part
      )}</strong>: ${part.instruments
        .map((inst) =>
          this.$options.filters.displayInstrumentName(
            this.$store.getters.instrument(inst),
            true,
            false
          )
        )
        .join(', ')}`;
    },
  },
};
