export default Object.freeze({
  slogan: `Helping Educators and Musicians Shine Since 1872`,
  logoPath: `/assets/images/logo/CF_lockup_sticky_wht@2x.png`,
  logoAlt: `Carl Fischer`,
  wtcLogoPath: `/assets/images/logo/WTC_logo.svg`,
  wtcLogoAlt: `Well Tempered Consort, Bach customized for your Ensemble`,
  fullLogo: `/assets/images/logo/CF-logo-lockup_wht.png`,
  fullLogoAlt: `Carl Fischer`,
  copyright: `Copyright © ${new Date().getFullYear()} by Carl Fischer, LLC`,
  jsbLogoPath: `/assets/images/logo/JSB-loader.gif`,
  jsbLogoAlt: `JSB`,
  isScreenSm: (width) => width <= 768,
  isScreenMd: (width) => width <= 992 && width > 768,
  isScreenLg: (width) => width > 1200,
});
