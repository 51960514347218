<template>
  <div>
    <div v-if="compact">
      <b-skeleton width="20%" />
    </div>
    <section class="section" v-else>
      <JSB />
      <b-skeleton :count="5" v-if="!logoLoaded" />
    </section>
  </div>
</template>

<script>
import JSB from '@/components/JSB';
import $eventHub from '@/components/EventHub';

export default {
  name: 'Suspense',
  components: {
    JSB,
  },
  props: {
    compact: Boolean,
  },
  data() {
    return {
      logoLoaded: false,
    };
  },
  mounted() {
    $eventHub.$on('jsbLoaded', this.toggleLoader);
  },
  methods: {
    toggleLoader() {
      this.logoLoaded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/styles';
.section {
  display: flex;
  flex-flow: column;
  justify-content: center;
  min-height: 50vh;

  .b-skeleton-item {
    margin: $gap auto;
  }
}
</style>
